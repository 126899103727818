'use client';

import SpaceBackground from '../ui/space-hero-ui/SpaceBackground';
import FlashButton from '../ui/space-hero-ui/FlashButton';
import Companies from '../ui/space-hero-ui/Companies';
import { Libre_Baskerville } from 'next/font/google';
import { sf_pro } from '@/fonts';

const libreBaskerville = Libre_Baskerville({
  weight: '400',
  style: 'italic',
  subsets: ['latin'],
  display: 'swap',
});

const HeroSection = ({ heroData }) => {
  return (
    <div
      className={`${sf_pro.className} lg:h-[130vh] h-[100vh] w-screen text-center text-white z-10 flex flex-col justify-end overflow-hidden items-center relative`}
    >
      <SpaceBackground />

      <div className="z-0 max-w-[1000px] w-full h-full flex text-center flex-col gap-y-3 mt-20 px-4 md:p-0 items-center justify-center">
        <div className="text-center text-4xl md:text-[60px] font-bold p-3 md:leading-[54px] lg:block flex flex-wrap justify-center">
          {heroData?.hero_text?.map((item, idx) => {
            return (
              <span
                key={idx}
                style={{ margin: '0 6px' }}
                className={[
                  item?.style === 'style_one'
                    ? 'text-transparent tracking-wide bg-clip-text bg-gradient-to-tr from-[#31B1D9] to-[#3DD771]'
                    : '',
                  item?.style === 'style_two' ? libreBaskerville.className : '',
                  item?.style === 'normal'
                    ? 'text-transparent bg-gradient-to-tr bg-clip-text from-white via-white/80 to-white/60'
                    : '',
                ]
                  .filter(Boolean)
                  .join(' ')}
              >
                {item?.text}
              </span>
            );
          })}
        </div>

        <div className="text-md leading-7 lg:w-[500px] w-full bg-gradient-to-b md:text-[18px] text-transparent from-white via-white/80 to-white/60 bg-clip-text">
          {heroData?.hero_desc}
        </div>
        <div className="relative lg:w-[500px] w-full h-28 flex items-center justify-center">
          <FlashButton
            text={heroData?.button2_text}
            url={heroData?.button2_url}
          />
        </div>
      </div>

      <Companies heading={heroData?.icons_heading} icons={heroData?.icons} />
    </div>
  );
};

export default HeroSection;
